import React, { useState } from "react"
import { Button, Modal } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import ImageGallery from "../components/ImageGallery"

const Alliances = () => {

//Hooks
  const [dataContact, setDataContact] = useState(false)

//Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Alliances"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Alliances</div>
            <div className="usp-txt">Le choix de vos alliances est très important,<br/>il reflète l'Amour de votre
              moitié !
            </div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>

        <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Alliances</h2>


        <h3 className="h3-mobile"  style={{textAlign: 'center'}}>Rendez-nous visite pour un conseil personnalisé.</h3>
        <p className="p-2 p-font mobile-display"  style={{textAlign: 'center'}}>Rendez-nous visite pour un conseil personnalisé.</p>

        <section>
          <ImageGallery id="alliances" />

          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Alliances') }>Nous contacter</Button></div>
          <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
            visible={ showModalContact }
            onOk={ handleContactFormOk }
            onCancel={ handleContactFormCancel }
            okButtonProps={{ style: { display: 'none' } }}
          >

            <ContactForm page={dataContact} />

          </Modal>
        </section>

      </Layout>
    </>
  )
}

export default Alliances
